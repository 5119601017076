export const SET_BASIC_INFO = '@register/SET_BASIC_INFO';
export const SET_CUSTOMER_ID = '@register/SET_CUSTOMER_ID';
export const DELETE_BASIC_INFO = '@register/DELETE_BASIC_INFO';
export const SET_TOKEN = '@register/SET_TOKEN';

const initialState = {
    basicInfo: null,
    customerId: null,
    token: null
};

export function register(state = initialState, action) {
    switch (action.type) {
        case SET_BASIC_INFO:
            localStorage.setItem('basicInfo', JSON.stringify(action.basicInfo));
            return {
                ...state,
                basicInfo: action.basicInfo
            };
        case DELETE_BASIC_INFO:
            localStorage.removeItem('basicInfo');
            return {
                ...state,
                basicInfo: null
            };
        case SET_CUSTOMER_ID:
            localStorage.setItem('customerId', JSON.stringify(action.customerId));
            return {
                ...state,
                customerId: action.customerId
            };
        case SET_TOKEN:
            return {
                ...state,
                token: action.token
            };
        default:
            return state;
    }
}
