export const SET_MENU = '@ui/SET_MENU';
export const MENU_OPEN = '@ui/MENU_OPEN';
export const TOGGLE_MESSAGE = '@ui/TOGGLE_MESSAGE';

const initialState = {
  customization: {
    isOpen: [],
    opened: true
  },
  snackbar: {
    open: false,
    severity: null,
    message: null
  }
}

export function toggleGlobalMessage (open, severity, message) {
  return (dispatch) => dispatch({
    type: TOGGLE_MESSAGE,
    data: {
      open,
      severity,
      message
    }
  })
}

export const ui = (state = initialState, action) => {
  switch (action.type) {
    case MENU_OPEN:
      const id = action.id;
      return {
        ...state,
        customization: {
          ...state.customization,
          isOpen: [id]
        }
      };
    case SET_MENU:
      return {
        ...state,
        customization: {
          ...state.customization,
          opened: action.opened
        }
      };
    case TOGGLE_MESSAGE:
      console.log(action.data)
      return {
        ...state,
        snackbar: action.data
      }
    default:
      return state;
  }
};