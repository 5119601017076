import { Box, AppBar, Container, Toolbar, IconButton } from '@mui/material';
import LogoSection from '../LogoSection';
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from '../../../api/AuthApi';
import { useToast } from '../../../hooks/ToastProvider';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import useGlobalMutation from '../../../hooks/useGlobalMutation';

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = () => {
    const { showToast } = useToast();
    const location = useLocation();
    const token = localStorage.getItem('token');
    const isPageWithoutLogout = [
        ROUTES.LOGIN,
        ROUTES.ENTRY,
        ROUTES.PAYMENT,
        ROUTES.CONFIRMATION,
        ROUTES.CONFIRMATION_SUCCESS,
        ROUTES.SET_PASSWORD,
        ROUTES.REGISTER_SUCCESS
    ].includes(location.pathname);
    const history = useHistory();
    const logoutMutation = useGlobalMutation(logout, {
        onSuccess: async () => {
            localStorage.removeItem('token');
            history.push('/login');
        },
        onError: () => {
            showToast();
        }
    });
    const handleLogout = () => {
        logoutMutation.mutate();
    };
    return (
        <AppBar position="static" color="">
            <Container maxWidth="sm">
                <Toolbar disableGutters>
                    <Box component="span" sx={{ flexGrow: 1 }}>
                        <LogoSection />
                    </Box>
                    {!isPageWithoutLogout && token && (
                        <IconButton onClick={handleLogout} color="primary" sx={{ marginRight: '16px' }}>
                            <LogoutIcon />
                        </IconButton>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
