import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider, CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from './routes';

// defaultTheme
import theme from './themes';

//LocalizationProvider
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { jaJP } from '@mui/x-date-pickers/locales';

// project imports
import NavigationScroll from './layout/NavigationScroll';
import ToastProvider from './hooks/ToastProvider';
import { LoadingProvider } from './hooks/LoadingProvider';
import { QueryClient, QueryClientProvider } from 'react-query';

//-----------------------|| APP ||-----------------------//

const App = () => {
    const customization = useSelector((state) => state.ui.customization);
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: false
            }
        }
    });
    return (
        <StyledEngineProvider injectFirst>
            <ToastProvider>
                <QueryClientProvider client={queryClient}>
                    <LoadingProvider>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="ja"
                            localeText={jaJP.components.MuiLocalizationProvider.defaultProps.localeText}
                        >
                            <ThemeProvider theme={theme(customization)}>
                                <CssBaseline />
                                <NavigationScroll>
                                    <Routes />
                                </NavigationScroll>
                            </ThemeProvider>
                        </LocalizationProvider>
                    </LoadingProvider>
                </QueryClientProvider>
            </ToastProvider>
        </StyledEngineProvider>
    );
};

export default App;
