import PropTypes from 'prop-types';
import React from 'react';
// material-ui
import { Container, Box } from '@mui/material';
import Header from './Header';
import Footer from './Footer';

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children }) => {
    return (
        <Box style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <Box style={{ backgroundColor: '#c8e9fa', flex: 1 }}>
                <Container maxWidth="sm" style={{ paddingBottom: '32px' }}>
                    {children}
                </Container>
            </Box>
            <Footer />  
        </Box>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node
};

export default MainLayout;
