import { createTheme } from '@mui/material';

// assets
import colors from '../assets/scss/_themes-vars.module.scss';

// project imports
import { componentStyleOverrides } from './compStyleOverride';
import { themePalette } from './palette';
import { ja } from './locale';
import { themeTypography } from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */
export function theme(customization) {
    const color = colors;
    color.primaryMain = '#00adef';
    color.primaryDark = '#00adef';
    let themeOption = {
        colors: color,
        heading: color.grey900,
        paper: color.paper,
        backgroundDefault: color.paper,
        background: color.primaryLight,
        darkTextPrimary: '#000',
        darkTextSecondary: color.grey500,
        captionPrimary: color.grey600,
        textDark: color.grey900,
        menuSelected: color.primaryDark,
        menuSelectedBack: color.primaryLight,
        divider: color.grey200,
        customization: customization
    };

    return createTheme({
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920
            }
        },
        typography: themeTypography(themeOption),
        components: componentStyleOverrides(themeOption)
    });
}

export default theme;

export const LANG = ja;
