import React, { lazy, useEffect, useState } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../components/Loadable';
import { ROUTES } from '../constants';
import NotFound from '../components/NotFound';
import ErrorPage from '../components/ErrorPage';
import { SERVICE_TYPE } from '../constants/serviceType';
import { useDispatch } from 'react-redux';
import { SET_BASIC_INFO, SET_TOKEN } from '../redux/_register.redux';
import { REGISTER_STATUS } from '../constants/registerStatus';
import { checkUrlToken } from '../api/RegisterApi';
import { useQuery } from 'react-query';
import { loginByRegisterToken } from '../api/AuthApi';
import { useToast } from '../hooks/ToastProvider';
import { ERROR_CODE } from '../constants/errorCode';
import useGlobalMutation from '../hooks/useGlobalMutation';

// dashboard routing
const EntryDefault = Loadable(lazy(() => import('../views/entry')));
const PaymentDefault = Loadable(lazy(() => import('../views/payment')));
const ConfirmationDefault = Loadable(lazy(() => import('../views/confirmation')));
const ConfirmationSuccessDefault = Loadable(lazy(() => import('../views/confirmation_success')));
const EmailVerifiedDefault = Loadable(lazy(() => import('../views/email_verified')));
const RegisterSuccessDefault = Loadable(lazy(() => import('../views/register_success')));
const ContractDefault = Loadable(lazy(() => import('../views/contract')));
const EVInfoDefault = Loadable(lazy(() => import('../views/ev_info')));
const EVConfirmationDefault = Loadable(lazy(() => import('../views/ev_confirmation')));
const LoginDefault = Loadable(lazy(() => import('../views/login')));
const SetPasswordDefault = Loadable(lazy(() => import('../views/set_password')));
const SelectContractDefault = Loadable(lazy(() => import('../views/select_contract')));
const EntrySimpleDefault = Loadable(lazy(() => import('../views/entry_simple')));
const UpdateInfoDefault = Loadable(lazy(() => import('../views/update_info')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const currentPath = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [service, setService] = useState(searchParams.get('service'));
    const { showToast } = useToast();

    useEffect(() => {
        if (currentPath === ROUTES.ENTRY) setService(searchParams.get('service'));
    }, [searchParams]);
    const [registerStatus, setRegisterStatus] = useState();
    const steps = service === SERVICE_TYPE.EVC_MEMBER ? ['連絡情報入力', '確認'] : ['連絡情報入力', '支払情報入力', '確認'];

    const paths = [
        ROUTES.ENTRY,
        ROUTES.PAYMENT,
        ROUTES.CONFIRMATION,
        ROUTES.CONFIRMATION_SUCCESS,
        ROUTES.REGISTER_SUCCESS,
        ROUTES.EMAIL_VERIFIED,
        ROUTES.CONTRACT,
        ROUTES.SELECT_CONTRACT,
        ROUTES.EV_INFO,
        ROUTES.EV_CONFIRMATION,
        ROUTES.LOGIN,
        ROUTES.SET_PASSWORD,
        ROUTES.ENTRY_SIMPLE,
        ROUTES.UPDATE_INFO
    ];

    const routesWithoutTokenCheck = [ROUTES.ENTRY, ROUTES.LOGIN, ROUTES.REGISTER_SUCCESS, ROUTES.CONFIRMATION_SUCCESS, ROUTES.ENTRY_SIMPLE];
    const needAuthPaths = [ROUTES.EV_CONFIRMATION, ROUTES.CONTRACT, ROUTES.EV_INFO, ROUTES.SELECT_CONTRACT];

    const loginByRegisterTokenMutation = useGlobalMutation(loginByRegisterToken, {
        onSuccess: async (user) => {
            localStorage.clear();
            localStorage.setItem('token', user.token);
            indexedDB.deleteDatabase('EV_Database');
            history.push(`/contract?token=${user.token}&status=isVerified`);
        },
        onError: (e) => {
            if (e.statusCode === 401 && e.code === ERROR_CODE.InvalidCredentials) {
                showToast(e.message);
            } else {
                showToast();
            }
        }
    });

    const { isLoading: isLoadingCheckToken, error: checkTokenError } = useQuery(
        ['checkToken', token],
        () => checkUrlToken(token, currentPath === ROUTES.SET_PASSWORD, needAuthPaths.includes(currentPath)),
        {
            enabled: !routesWithoutTokenCheck.includes(currentPath) && paths.includes(currentPath),
            onSuccess: (res) => {
                setService(res.service);
                if (currentPath === ROUTES.SET_PASSWORD) {
                    if (res.registerStatus === REGISTER_STATUS.Completed) {
                        if (res.service === SERVICE_TYPE.EVC_OWNER) {
                            loginByRegisterTokenMutation.mutate({ token: token });
                        } else {
                            history.push(`/email_verified?token=${token}`);
                        }
                    } else {
                        setRegisterStatus(res.registerStatus);
                    }
                }
                dispatch({ type: SET_TOKEN, token: token });
                if (currentPath === ROUTES.CONFIRMATION || currentPath === ROUTES.PAYMENT) {
                    dispatch({ type: SET_BASIC_INFO, basicInfo: res });
                }
            }
        }
    );
    const renderMainLayout = (component) => <MainLayout>{component}</MainLayout>;

    const isServiceTypeValid = (service) => [SERVICE_TYPE.EVC_MEMBER, SERVICE_TYPE.EVC_OWNER, SERVICE_TYPE.EVC_PUBLIC].includes(service);
    const isInvalidEntryPage = () => currentPath === ROUTES.ENTRY && !isServiceTypeValid(service);
    const isInvalidPaymentPage = () => currentPath === ROUTES.PAYMENT && service === SERVICE_TYPE.EVC_MEMBER;
    const isInvalidSetPasswordPage = () => currentPath === ROUTES.SET_PASSWORD && registerStatus !== REGISTER_STATUS.EmailVerifyWaiting;

    const isPageNotFound = !paths.includes(currentPath) || isInvalidEntryPage() || isInvalidPaymentPage() || isInvalidSetPasswordPage();
    if (!isLoadingCheckToken && isPageNotFound) {
        return renderMainLayout(<Route path={currentPath} component={NotFound} />);
    }

    if (checkTokenError) {
        return renderMainLayout(<Route path={currentPath} component={ErrorPage} />);
    }

    const renderRoutes = () => (
        <Switch location={location} key={currentPath}>
            <Route path={ROUTES.ENTRY} component={() => <EntryDefault steps={steps} />} />
            <Route path={ROUTES.PAYMENT} component={() => <PaymentDefault steps={steps} />} />
            <Route path={ROUTES.CONFIRMATION} component={() => <ConfirmationDefault steps={steps} />} />
            <Route path={ROUTES.CONFIRMATION_SUCCESS} component={ConfirmationSuccessDefault} />
            <Route path={ROUTES.EMAIL_VERIFIED} component={EmailVerifiedDefault} />
            <Route path={ROUTES.REGISTER_SUCCESS} component={RegisterSuccessDefault} />
            <Route path={ROUTES.CONTRACT} component={ContractDefault} />
            <Route path={ROUTES.SELECT_CONTRACT} component={SelectContractDefault} />
            <Route path={ROUTES.EV_INFO} component={EVInfoDefault} />
            <Route path={ROUTES.EV_CONFIRMATION} component={EVConfirmationDefault} />
            <Route path={ROUTES.LOGIN} component={LoginDefault} />
            <Route path={ROUTES.SET_PASSWORD} component={() => <SetPasswordDefault service={service} />} />
            <Route path={ROUTES.ENTRY_SIMPLE} component={EntrySimpleDefault} />
            <Route path={ROUTES.UPDATE_INFO} component={UpdateInfoDefault} />
        </Switch>
    );
    return <>{!isLoadingCheckToken ? <Route path={paths}>{renderMainLayout(renderRoutes())}</Route> : <MainLayout />}</>;
};

export default MainRoutes;
