import { Card, Link, Typography, Box } from '@mui/material';
import NotFoundImage from '../assets/images/not_found.png';

function NotFound() {
    return (
        <div style={{ paddingTop: '64px' }}>
            <Card style={{ textAlign: 'center', padding: '40px', borderRadius: '25px' }}>
                <img src={NotFoundImage} style={{ maxWidth: 150 }} alt="logo" />
                <Typography variant="h2" mt={1}>
                    ページが見つかりません
                </Typography>
                <Box mt={3}>
                    <Link
                        fontSize={22}
                        underline="hover"
                        href={`${window.location.origin}/entry?service=EVC_OWNER`}
                        target="_blank"
                        style={{
                            fontWeight: 700
                        }}
                    >
                        TOPページへ
                    </Link>
                </Box>
            </Card>
        </div>
    );
}

export default NotFound;
