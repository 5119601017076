import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Typography } from '@mui/material';

// project imports
import config from './../../../config';
import Logo from './../../../components/Logo';
import { useSelector } from 'react-redux';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
    const credential = useSelector(state => state.auth.credential)
    return (
        <>
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            <Logo />
        </ButtonBase>
        <Typography variant="h4" style={{textAlign: 'center'}}>{credential?.shop?.name}</Typography>
        </>
    );
};

export default LogoSection;
