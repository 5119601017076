import { ApiConst } from '../constants'

const GET_USERS = '@users/GET_USERS'
const GET_USERS_SUCCESS = '@users/GET_USERS_SUCCESS'
const GET_USERS_FAIL = '@users/GET_USERS_FAIL'

export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_FAIL = 'GET_USER_DETAIL_FAIL';

export const START_EDIT_USER = "START_EDIT_USER";
export const END_EDIT_USER = "END_EDIT_USER";
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const START_CREATE_USER = 'START_CREATE_USER';
export const END_CREATE_USER = 'END_CREATE_USER';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const START_DELETE_USER = "START_DELETE_USER";
export const END_DELETE_USER = "END_DELETE_USER";
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const START_ADD_USER_RECEIVER = "START_ADD_USER_RECEIVER";
export const END_ADD_USER_RECEIVER = "END_ADD_USER_RECEIVER";
export const ADD_USER_RECEIVER = 'ADD_USER_RECEIVER';
export const ADD_USER_RECEIVER_SUCCESS = 'ADD_USER_RECEIVER_SUCCESS';
export const ADD_USER_RECEIVER_FAIL = 'ADD_USER_RECEIVER_FAIL';

const initialState = {
  list: {
    params: {},
    page: 0,
    pageSize: 0,
    total: 0,
    items: [],
    loading: false,
    error: null
  },
  edit: {
    id: null,
    data: null,
    result: null,
    detail: null,
    loading: false,
    error: null,
  },
  create: {
    open: false,
    data: null,
    result: null,
    loading: false,
    error: null,
  },
  delete: {
    selected: null
  },
  addUserReceiver: {
    open: false,
    data: null,
    result: null,
    loading: false,
    error: null,
  }
}

export function getUsersAction(params) {
  return (dispatch) => dispatch({
    types: [GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAIL], 
    promise: api => api.get(ApiConst.USERS, params),
    params,
    secure: true
  })
}

export function getUserDetailAction (id) {
  return (dispatch) => dispatch({
    types: [GET_USER_DETAIL, GET_USER_DETAIL_SUCCESS, GET_USER_DETAIL_FAIL], 
    promise: api => api.get(`${ApiConst.USERS}/${id}`),
    secure: true
  })
}

export function updateUserAction (id, data) {
  return (dispatch) => dispatch({
    types: [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL], 
    promise: api => api.put(`${ApiConst.USERS}/${id}`, data),
    data,
    secure: true
  })
}

export function createUserAction (data) {
  return (dispatch) => dispatch({
    types: [CREATE_USER, CREATE_USER_SUCCESS, CREATE_USER_FAIL], 
    promise: api => api.post(`${ApiConst.USERS}`, data),
    data,
    secure: true
  })
}

export function deleteUserAction (id) {
  return (dispatch) => dispatch({
    types: [DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAIL], 
    promise: api => api.delete(`${ApiConst.USERS}/${id}`),
    secure: true
  })
}

export function addUserReceiverAction (data) {
  return (dispatch) => dispatch({
    types: [ADD_USER_RECEIVER, ADD_USER_RECEIVER_SUCCESS, ADD_USER_RECEIVER_FAIL], 
    promise: api => api.post(`${ApiConst.USERS}-receiver`, data),
    data,
    secure: true
  })
}

export function user(state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
        }
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.data,
          loading: false,
        }
      }
    case GET_USERS_FAIL:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.error
        }
      }
    
    case GET_USER_DETAIL:
      return {
        ...state,
        edit: {
          ...state.edit,
          detail: null,
          loading: true,
          error: null
        }
      }
    case GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          detail: action.data,
          loading: false,
          error: null
        }
      }
    case GET_USER_DETAIL_FAIL:
      return {
        ...state,
        edit: {
          ...state.edit,
          detail: null,
          loading: false,
          error: action.error
        }
      }
    
    /* edit user */
    case START_EDIT_USER:
      return {
        ...state,
        edit: {
          ...state.edit,
          id: action.id
        }
      }
    case END_EDIT_USER:
      return {
        ...state,
        edit: {
          ...state.edit,
          id: null
        }
      }
    case UPDATE_USER:
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: true,
          error: null,
          result: null
        }
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: false,
          result: action.data
        }
      }
    case UPDATE_USER_FAIL:
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: false,
          error: action.error
        }
      }
    
    //create user
    case START_CREATE_USER:
      return {
        ...state,
        create: {
          open: true
        }
      }
    case END_CREATE_USER:
      return {
        ...state,
        create: {
          open: false
        }
      }
    case CREATE_USER:
      return {
        ...state,
        create: {
          ...state.create,
          data: action.data,
          loading: true,
          error: null,
          result: null
        }
      }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          result: action.data
        }
      }
    case CREATE_USER_FAIL:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.error
        }
      }
    
    // delete user
    case START_DELETE_USER:
      return {
        ...state,
        delete: {
          ...state.delete,
          selected: action.selected,
          error: null
        }
      }
    case END_DELETE_USER:
      return {
        ...state,
        delete: {
          ...state.delete,
          selected: null
        }
      }
    case DELETE_USER:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: true,
          error: null
        }
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false
        }
      }
    case DELETE_USER_FAIL:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          error: action.error
        }
      }

    //add user with receiver
    case START_ADD_USER_RECEIVER:
      return {
        ...state,
        addUserReceiver: {
          open: true
        }
      }
    case END_ADD_USER_RECEIVER:
      return {
        ...state,
        addUserReceiver: {
          open: false
        }
      }
    case ADD_USER_RECEIVER:
      return {
        ...state,
        addUserReceiver: {
          ...state.addUserReceiver,
          data: action.data,
          loading: true,
          error: null,
          result: null
        }
      }
    case ADD_USER_RECEIVER_SUCCESS:
      return {
        ...state,
        addUserReceiver: {
          ...state.addUserReceiver,
          loading: false,
          result: action.data
        }
      }
    case ADD_USER_RECEIVER_FAIL:
      return {
        ...state,
        addUserReceiver: {
          ...state.addUserReceiver,
          loading: false,
          error: action.error
        }
      }
    
    default:
      return state
  }
}
