import { ApiConst } from '../constants'

export const LOGIN = 'auth/LOGIN';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'auth/LOGIN_FAIL';
export const UPDATE_TOKEN = 'auth/UPDATE_TOKEN';
export const GRANT = 'auth/GRANT';
export const GRANT_SUCCESS = 'auth/GRANT_SUCCESS';
export const GRANT_FAIL = 'auth/GRANT_FAIL';
export const PERSIST_DONE = 'auth/PERSIST_DONE';

export const LOGOUT = 'auth/LOGOUT';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'auth/LOGOUT_FAIL';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'auth/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'auth/CHANGE_PASSWORD_FAIL';

const initialState = {
  loading: false,
  credential: null,
  token: null,
  refreshToken: null,
  roles: null,
  error: null,
  updated: false,
  profile: {
    loading: false,
    defail: null,
    error: null
  }
}

export function loginAction(data) {
  return (dispatch) => dispatch({
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL], 
    promise: api => api.post(ApiConst.LOGIN, data)
  })
}

export function logoutAction() {
  return (dispatch) => dispatch({
    type: LOGOUT
  })
}

export function getDetailProfileAction() {
  return (dispatch) => dispatch({
    types: [GET_PROFILE, GET_PROFILE_SUCCESS, GET_PROFILE_FAIL], 
    promise: api => api.get(ApiConst.PROFILE),
    secure: true
  })
}

export function changePasswordAction (data) {
  return dispatch => dispatch({
    types: [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
    promise: api => api.post(ApiConst.AUTH_PASSWORD, data),
    secure: true
  })
}

export function auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true
      }
    case LOGIN_SUCCESS:
      return {
        ...state, 
        loading: false, 
        ...action.data,
        updated: true
      }
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        refreshToken: null,
        credential: null,
        updated: true
      }

    case UPDATE_TOKEN:
      return {
        ...state,
        loading:false,
        token: action.data.token,
        refreshToken: action.data.refreshToken,
        updated: true
      };
    case PERSIST_DONE:
      return {
        ...state,
        updated: false
      }
    case GET_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: true,
          error: null
        }
      }
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          loading: false,
          detail: action.data
        }
      }
    case GET_PROFILE_FAIL:
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: false,
          error: action.error
        }
      }
    default:
      return state
  }
}
