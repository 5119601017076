import { ApiFactory } from './Api';

const api = new ApiFactory();

async function checkUrlToken(token, getRegisterStatus, needAuth) {
    const response = await api.post('/registration/token/verified', { token: token, getRegisterStatus, needAuth });
    return response;
}

async function entry(request) {
    const response = await api.post('/registration/entry', request);
    return response.data;
}

async function payment(request) {
    const response = await api.post('/registration/payment', request);
    return response.data;
}

async function confirmation(request) {
    const response = await api.post('/confirmation', request);
    return response.data;
}

async function verified(request) {
    const response = await api.post('/registration/verified', request);
    return response.data;
}

async function updateInfo(request) {
    const response = await api.put('/registration/accounts', request);
    return response.data;
}

async function check_duplicate(request) {
    const response = await api.post('/registration/contracts/check_duplicate', request);
    return response.data;
}

export { checkUrlToken, entry, payment, confirmation, verified, updateInfo, check_duplicate };
