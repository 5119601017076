import { ApiConst } from '../constants'

export const GET_BATTERIES = '@battery/GET_BATTERIES';
export const GET_BATTERIES_SUCCESS = '@battery/GET_BATTERIES_SUCCESS';
export const GET_BATTERIES_FAIL = '@battery/GET_BATTERIES_FAIL';

export const GET_BATTERY_DETAIL = '@battery/GET_BATTERY_DETAIL';
export const GET_BATTERY_DETAIL_SUCCESS = '@battery/GET_BATTERY_DETAIL_SUCCESS';
export const GET_BATTERY_DETAIL_FAIL = '@battery/GET_BATTERY_DETAIL_FAIL';

export const START_EDIT_BATTERY = "@battery/START_EDIT_BATTERY";
export const END_EDIT_BATTERY = "@battery/END_EDIT_BATTERY";
export const UPDATE_BATTERY = '@battery/UPDATE_BATTERY';
export const UPDATE_BATTERY_SUCCESS = '@battery/UPDATE_BATTERY_SUCCESS';
export const UPDATE_BATTERY_FAIL = '@battery/UPDATE_BATTERY_FAIL';

export const START_DELETE_BATTERY = "@battery/START_DELETE_BATTERY";
export const END_DELETE_BATTERY = "@battery/END_DELETE_BATTERY";
export const DELETE_BATTERY = '@battery/DELETE_BATTERY';
export const DELETE_BATTERY_SUCCESS = '@battery/DELETE_BATTERY_SUCCESS';
export const DELETE_BATTERY_FAIL = '@battery/DELETE_BATTERY_FAIL';

export const START_CREATE_BATTERY = '@battery/START_CREATE_BATTERY';
export const END_CREATE_BATTERY = '@battery/END_CREATE_BATTERY';
export const CREATE_BATTERY = '@battery/CREATE_BATTERY';
export const CREATE_BATTERY_SUCCESS = '@battery/CREATE_BATTERY_SUCCESS';
export const CREATE_BATTERY_FAIL = '@battery/CREATE_BATTERY_FAIL';

const initialState = {
  list: {
    params: {},
    page: 0,
    pageSize: 0,
    total: 0,
    items: [],
    loading: false,
    error: null
  },
  detail: {
    id: null,
    detail: null,
    loading: false,
    error: null,
  },
  edit: {
    id: null,
    data: null,
    result: null,
    detail: null,
    loading: false,
    error: null,
  },
  create: {
    open: false,
    data: null,
    result: null,
    loading: false,
    error: null,
  },
  delete: {
    selected: null
  }
}


export function getBatterysAction (params) {
  return (dispatch) => dispatch({
    types: [GET_BATTERIES, GET_BATTERIES_SUCCESS, GET_BATTERIES_FAIL], 
    promise: api => api.get(ApiConst.BATTERIES, params),
    params,
    secure: true,
  })
}


export function createBatteryAction (data) {
  return (dispatch) => dispatch({
    types: [CREATE_BATTERY, CREATE_BATTERY_SUCCESS, CREATE_BATTERY_FAIL], 
    promise: api => api.post(`${ApiConst.BATTERIES}`, data),
    data,
    secure: true
  })
}

export function getBatteryDetailAction (id) {
  return (dispatch) => dispatch({
    types: [GET_BATTERY_DETAIL, GET_BATTERY_DETAIL_SUCCESS, GET_BATTERY_DETAIL_FAIL], 
    promise: api => api.get(`${ApiConst.BATTERIES}/${id}`),
    secure: true
  })
}

export function updateBatteryAction (id, data) {
  return (dispatch) => dispatch({
    types: [UPDATE_BATTERY, UPDATE_BATTERY_SUCCESS, UPDATE_BATTERY_FAIL], 
    promise: api => api.put(`${ApiConst.BATTERIES}/${id}`, data),
    data,
    secure: true
  })
}


export function deleteBatteryAction (id) {
  return (dispatch) => dispatch({
    types: [DELETE_BATTERY, DELETE_BATTERY_SUCCESS, DELETE_BATTERY_FAIL], 
    promise: api => api.delete(`${ApiConst.BATTERIES}/${id}`),
    secure: true
  })
}

// request manual mode on or off
export function requestManualMode(onoff) {
  
}


export function battery(state = initialState, action) {
  switch (action.type) {
    case GET_BATTERIES:
      return {
        ...state,
        list: {
          ...state.list,
          params: action.params,
          loading: true,
          error: null
        }
      }
    case GET_BATTERIES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          ...action.data
        }
      }
    case GET_BATTERIES_FAIL:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.error
        }
      }
    
    case GET_BATTERY_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          detail: null,
          loading: true,
          error: null
        }
      }
    case GET_BATTERY_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          detail: action.data,
          loading: false,
          error: null
        }
      }
    case GET_BATTERY_DETAIL_FAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          detail: null,
          loading: false,
          error: action.error
        }
      }
    
    /* edit battery */
    case START_EDIT_BATTERY:
      return {
        ...state,
        edit: {
          ...state.edit,
          id: action.id
        }
      }
    case END_EDIT_BATTERY:
      return {
        ...state,
        edit: {
          ...state.edit,
          id: null
        }
      }
    case UPDATE_BATTERY:
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: true,
          error: null,
          result: null
        }
      }
    case UPDATE_BATTERY_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: false,
          result: action.data
        }
      }
    case UPDATE_BATTERY_FAIL:
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: false,
          error: action.error
        }
      }
    
    //create battery
    case START_CREATE_BATTERY:
      return {
        ...state,
        create: {
          open: true
        }
      }
    case END_CREATE_BATTERY:
      return {
        ...state,
        create: {
          open: false
        }
      }
    case CREATE_BATTERY:
      return {
        ...state,
        create: {
          ...state.create,
          data: action.data,
          loading: true,
          error: null,
          result: null
        }
      }
    case CREATE_BATTERY_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          result: action.data
        }
      }
    case CREATE_BATTERY_FAIL:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.error
        }
      }
    
    // delete battery
    case START_DELETE_BATTERY:
      return {
        ...state,
        delete: {
          ...state.delete,
          selected: action.selected,
          error: null
        }
      }
    case END_DELETE_BATTERY:
      return {
        ...state,
        delete: {
          ...state.delete,
          selected: null
        }
      }
    case DELETE_BATTERY:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: true,
          error: null
        }
      }
    case DELETE_BATTERY_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false
        }
      }
    case DELETE_BATTERY_FAIL:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          error: action.error
        }
      }
    
    default:
      return state
  }
}
