import { useMutation } from 'react-query';
import { useContext } from 'react';
import LoadingContext from './LoadingProvider';

const useGlobalMutation = (mutationFn, options) => {
    const { setIsLoading } = useContext(LoadingContext);

    return useMutation(mutationFn, {
        ...options,
        onMutate: () => {
            setIsLoading(true);
            if (options?.onMutate) {
                options.onMutate();
            }
        },
        onSettled: () => {
            setIsLoading(false);
            if (options?.onSettled) {
                options.onSettled();
            }
        }
    });
};

export default useGlobalMutation;