import React from 'react';

// material-ui
import LogoImage from '../assets/images/logo.webp';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from './../../assets/images/logo-dark.svg';
 * import logo from './../../assets/images/logo.svg';
 *
 */

//-----------------------|| LOGO SVG ||-----------------------//

const Logo = () => {

    return (
        <img src={LogoImage} style={{maxWidth: 200}} alt="logo"/>
    );
};

export default Logo;
