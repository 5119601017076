export const SET_CHARGERS = '@ev/SET_CHARGERS';

const initialState = {
    chargers: []
};

export function ev(state = initialState, action) {
    switch (action.type) {
        case SET_CHARGERS:
            return {
                ...state,
                chargers: action.chargers
            };
        default:
            return state;
    }
}
