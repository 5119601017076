import SnackbarError from '../components/SnackbarError';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

const ToastContext = createContext({
    isOpen: false
});
const UseToastContext = createContext({
    showToast: () => {}
});

function ToastProvider({ children }) {
    const [isOpen, setIsOpen] = useState(false);

    const [message, setMessage] = useState();
    const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

    const ToastContextValue = useMemo(
        () => ({
            isOpen,
            message
        }),
        [isOpen, message]
    );

    const useToastContextValue = useMemo(
        () => ({
            showToast: (message) => {
                setIsOpen(true);
                setMessage(message);
            }
        }),
        [setIsOpen, setMessage]
    );

    return (
        <ToastContext.Provider value={ToastContextValue}>
            <UseToastContext.Provider value={useToastContextValue}>
                {children}
                {ToastContextValue.isOpen && (
                    <SnackbarError isOpen={ToastContextValue.isOpen} message={ToastContextValue.message} onChange={() => onClose()} />
                )}
            </UseToastContext.Provider>
        </ToastContext.Provider>
    );
}

export default ToastProvider;
export function useToast() {
    return useContext(UseToastContext);
}
